<template>
  <div class="container-fluid">
    <div class="calendar-app pt-0 pb-0">
      <div class="row" style="background-color: rgb(229, 229, 229); min-height: 960px">
        <div class="col-4 pl-0 pr-2">
          <avatar-calendar
            ref="vCalendar"
            :attributes="attributes"
            @onPageChange="onPageChange"
            @changeDateTime="changeDateTime"
            :is-month="false"
          ></avatar-calendar>
        </div>
      </div>
      <div
        class="col-8 pr-0 pl-0 mt-3"
        style="position: absolute; top: 0; right: 0; background-color: #ffffff; height: 100%;"
      >
        <transition name="bounce">
          <div v-show="eventId != null" class="col-12 pl-0 pr-0">
            <div style="float: right; cursor: pointer;" @click="eventId = null">
              <i
                class="fas fa-times"
                style="color: red; font-size: medium; margin-top: 8px; margin-right: 5px;"
              ></i>
            </div>
            <attendance-list-dialog
              ref="attendanceListDialog"
              :isOutSideMeeting="true"
              @goMeeting="goMeeting"
            ></attendance-list-dialog>
          </div>
        </transition>
        <!-- not found meeting -->
        <div
          v-if="eventId == null && eventListSelected == null | eventListSelected == ''"
          class="col-md-12 mt-3 text-center font-weight-light"
        >{{ $t('calendar.noMeeting') }}</div>
        <div class="p-1" v-if="eventId == null && eventListSelected != null | eventListSelected != ''">
          <div
            v-for="(item, index) in eventListSelected"
            :key="item+index"
            :style="'background: #ECEFF0; font-size: 15px;'"
          >
            <div @click="selectEvent(item)"
              class="col-12 pt-3 pb-3 border-bottom border-white text-left event-info-data"
            >
              <div
                class="meeting-font-large"
                style="font-weight: bold;"
              >{{item.popover ? item.popover.label : '' }}</div>
              <div class="meeting-font-medium">
                <i class="far fa-calendar-check" style="color: orange; margin-right: 15px;"></i>
                <span>{{ formatEventDateTime(formatDateToString(item.dates[0].start), formatDateToString(item.dates[0].end)) }}</span>
              </div>
              <div class="meeting-font-medium" v-if="item.popover.event_venue_name != null && item.popover.event_venue_name != ''">
                <i class="fas fa-map-marker-alt" style="color: #007bff; margin-right: 15px;"></i>
                <span>{{item.popover.event_venue_name}}</span>
               </div>
              <div class="meeting-font-medium" v-if="item.popover.organization_name != null && item.popover.organization_name != ''">
                <i class="fas fa-user-tie" style="color: pink; margin-right: 15px;"></i>
                <span>{{item.popover.organization_name}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <confirm-modal ref="confirmModal" :content="confirmModalContent" @onConfirm="onConfirm()"></confirm-modal>
    <!-- Event list modal -->
    <b-modal
      v-model="showEventListModal"
      centered
      :hide-header="true"
      :hide-footer="true"
      id="modal-center"
      style="padding: 0;"
    >
      <div class="modal-header">
        <h5 class="modal-title">
          {{ $t('event.ongoingEvents') }}
          <br />
          {{ dateSelected }}
        </h5>
      </div>
      <div class="container p-0 mt-2 mb-2">
        <div v-for="(eventSelected, index) in eventListSelected" :key="eventSelected + index">
          <div
            class="event-item"
            style="display: flex; justify-content: space-between; margin: 5px 0 5px 0; padding: 10px 15px 10px 15px; cursor: pointer;"
            @click="selectEvent(eventSelected)"
          >
            <div>
              <span
                style="align-self: center;"
              >{{ eventSelected.popover ? eventSelected.popover.label : '' }}</span>
            </div>
            <div style="cursor: pointer; align-self: center;" @click="selectEvent(eventSelected)">
              <i class="fas fa-info-circle" style="color: green; font-size: 20px;"></i>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import dateUtils from "utils/dateUtils";
import moment from "moment";
import AvatarCalendar from "Components/AvatarCalendar";
import ConfirmModal from "Components/ConfirmModal";
import AttendanceListDialog from "Components/AttendanceListDialog";
import functionUtils from "utils/functionUtils";
import historyActionEnum from 'enum/historyLogEnum'
export default {
  components: {
    confirmModal: ConfirmModal,
    avatarCalendar: AvatarCalendar,
    attendanceListDialog: AttendanceListDialog,
  },
  data: function () {
    return {
      confirmModalContent: "meeting.confirmChooseMeeting",
      eventId: null,
      weekDaysByWeekNumber: [],
      attributes: [],
      eventTitle: null,
      showEventListModal: false,
      eventListSelected: [],
      dateSelected: null,
      count: 0
    };
  },
  computed: {
    ...mapGetters(["GET_EVENT_WITH_DATE_DATA"]),
  },
  watch: {
    GET_EVENT_WITH_DATE_DATA: function () {
      this.calendarEvents = [];
      let events = this.GET_EVENT_WITH_DATE_DATA;
      this.attributes = [
        {
          key: "today",
          highlight: {
            color: 'green',
          },
          dates: new Date(),
        },
      ];
      for (let i = 0, size = events.length; i < size; i++) {
        let calendarEventObj = {
          key: events[i].id,
          dot: "red",
          popover: {
            label: events[i].name,
            organization_name: events[i].organization_name,
            event_venue_name: events[i].event_venue_name,
            event_id: events[i].id
          },
          highlight: true,
          dates: [
            {
              start: new Date(events[i].start_datetime),
              end: new Date(events[i].end_datetime)
            },
          ],
        };
        this.attributes.push(calendarEventObj);
        // Set default selected event if current date exist event
        let startDate = events[i].start_datetime;
        startDate = dateUtils.formatDate(
          events[i].start_datetime,
          dateUtils.STATIC_FORMAT_DATE_TIME_ZONE_T,
          "YYYY-MM-DD"
        );
        let endDate = events[i].start_datetime;
        endDate = dateUtils.formatDate(
          events[i].end_datetime,
          dateUtils.STATIC_FORMAT_DATE_TIME_ZONE_T,
          "YYYY-MM-DD"
        );
        let currentDate = moment().format("YYYY-MM-DD");
        if ( startDate <= currentDate && currentDate <= endDate && this.count == 1) {
          calendarEventObj.dates[0].start = new Date(events[i].start_datetime)
          calendarEventObj.dates[0].end = new Date(events[i].end_datetime)
          let eventUniqueExist = this.eventListSelected.filter(element => {
            return element.key == calendarEventObj.key
          })
          if (eventUniqueExist.length == 0) {
            this.eventListSelected.push(calendarEventObj)
          }
        }
      }
      this.$refs.vCalendar.resetCount();
    },
  },
  methods: {
    formatDateToString: function(date){
      return moment(date).format('YYYY/MM/DD HH:mm')
    },
    formatEventDateTime: function(startTime, endTime){
      return functionUtils.formatEventDateTime(startTime, endTime)
    },
    /**
     * Select event
     */
    selectEvent: function (event) {
      // this.showEventListModal = false;
      this.eventId = event.key;
      this.eventTitle = event.label ? event.label : event.popover.label;
      this.$refs.attendanceListDialog.getAttendeeSpeakerList(this.eventId);
    },
    // Go meeting
    goMeeting: function () {
      this.$refs.confirmModal.onShowModalConfirm(this.eventTitle);
    },
    /**
     * Fetch data event
     */
    fetchData: function (date) {
      // let month = moment(date.month).format('MM');
      // let year = moment(date.year).format('YYYY')
      let monthTimecurrentStart = date.year + "-" + date.month
      let endDatecurrentStart = moment(monthTimecurrentStart).endOf('month').utc().format('YYYY-MM-DD HH:mm:ss')
      let statTime = moment(monthTimecurrentStart+"-"+"1").utc().format('YYYY-MM-DD HH:mm:ss');
      let endTime = endDatecurrentStart;
      let filter = {
        params: {
          calendarType: "month",
          statTime: statTime,
          endTime: endTime,
        },
      };
      this.GET_EVENT_WITH_DATE(filter);
    },
    /**
     * On page change
     */
    onPageChange: function (date) {
      this.count += 1
      this.fetchData(date);
    },
    /**
     * Change date time
     */
    changeDateTime: function (date) {
      if (
        !functionUtils.isNull(date.attributes) &&
        date.attributes.length > 0 &&
        date.attributes[0].key != "today"
      ) {
        if (date.attributes && date.attributes.length > 0) {
          let eventList = this.GET_EVENT_WITH_DATE_DATA
          this.eventListSelected = date.attributes.filter((element) => {
            let index = eventList.findIndex(x=> x.id === element.popover.event_id)
            element.dates[0].start = new Date(eventList[index].start_datetime)
            element.dates[0].end = new Date(eventList[index].end_datetime)
            return !functionUtils.isNull(element.popover);
          });
          this.dateSelected = date.ariaLabel;
          // this.showEventListModal = true;
          this.eventId = null;
        } else {
          this.dateSelected = null;
          this.eventListSelected = null;
          this.showEventListModal = false;
          this.eventId = date.attributes[0].key;
          this.eventTitle = date.popovers[0].label;
          this.$refs.attendanceListDialog.getAttendeeSpeakerList(this.eventId);
        }
      } else {
        this.showEventListModal = false;
        this.eventSelected = null;
        this.eventListSelected = null;
        this.eventId = null;
      }
    },
    /**
     * On confirm
     */
    onConfirm: function () {
      this.$store.state.app.inAMeeting = true;
      this.$router.push({
        name: "information.title",
      });
      sessionStorage.setItem("event_id", this.eventId);
      let data = {
        action_type: historyActionEnum.JOIN_MEETING,
        description: this.$t('historyLog.descriptionText.joinMeeting'),
        event_id: this.eventId
      }
      this.createHistoryLog(data)
    },
    getWeekDaysByWeekNumber(weeknumber) {
      var dateformat = "YYYY/MM/DD";
      var date = moment()
          .isoWeek(weeknumber || 1)
          .startOf("week"),
        weeklength = 7,
        result = [];
      while (weeklength--) {
        let object = {
          day: date.format("dddd"),
          date: date.format(dateformat),
        };
        result.push(object);
        date.add(1, "day");
      }
      return result;
    },
    compareDate: function (start, end) {
      let startTime = moment(start)
      let endTime = moment(end)
      if (startTime.format("DD-MM-YYYY") === endTime.format("DD-MM-YYYY")) {
        return startTime.format("DD/MM/YYYY");
      } else return startTime.format("DD/MM/YYYY") + " - " + endTime.format("DD/MM/YYYY");
    },
    createHistoryLog: function (data) {
      this.CREATE_HISTORY_LOG(data).then(
        function () {
        }.bind(this)
      )
    },
    ...mapActions(["GET_EVENT_WITH_DATE", "GET_EVENT_USER_REGISTERED", "CREATE_HISTORY_LOG"]),
  },
};
</script>
<style lang="scss" scoped>
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";
@import "../../style/color.scss";
.calendar-app {
  position: relative;
  padding: 5px 0px 15px 0px;
  font-size: 14px;
}
.calendar-app-top {
  margin: 0 0 3em;
}
.calendar-app-calendar {
  margin: 0 auto;
  margin-top: 15px;
  width: 100%;
}
/deep/ .fc-button-primary {
  background-color: $primary !important;
  border: none;
}
/deep/ .fc-today-button {
  background-color: $light-primary !important;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
/deep/ .modal-body {
  padding: 0;
}
.event-item:hover {
  background-color: #e5e5e5;
}
.event-info-data:hover{
  background: #bee3f8 !important;
}
</style>